<template>
  <div class="tab" >
    <div class="tab__menu">
      <slot name="menu">
        <div
          class="menu__item"
          :class="{
            'menu__item--active': tab_aktif == item.id
          }"
          v-for="(item, index) of listTab"
          :key="index"
          @click.stop="$emit('changeTab', item.id)"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <slot name="title" v-bind="item">
                  {{ item.title }}
                </slot>
              </div>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
        </div>
      </slot>
    </div>
    <div
      class="tab__content"
      :class="{ 'tab__content--active': tab_aktif == content.id }"
      v-for="(content, index) of listContent"
      :key="index"
    >
      <div class="tab__select pb-sm-0">
        <v-select
          @change="changeMobileTab()"
          v-model="tab_aktif"
          :items="listTab"
          item-text="title"
          item-value="id"
          dense
        >
          <template v-slot:item="data">
            <div
              :class="{
                'pr-close-mobile': data.item.family == true,
              }"
            >
              {{ data.item.title }}
              <div>
                <v-btn
                  icon
                  class="ic-close"
                  x-small
                  v-if="data.item.id > 7"
                  @click.stop="$emit('close', data.item.id)"
                >
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
        </v-select>
      </div>
      <slot name="tabs_content" v-bind="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Number,
      default: 0,
    },
    listTab: {
      type: Array,
      default: () => [],
    },
    listContent: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: [String, Number],
      default: "",
    },
  },
  data: function () {
    return {
      tab_aktif: this.activeTab,
      env: process.env.VUE_APP_ENVIRONMENT_NAME,
    };
  },
  watch: {
    activeTab: function (new_val, old_val) {
      if (old_val !== 9999999) {
        this.tab_aktif = new_val;
      }
    },
  },
  methods: {
    changeMobileTab() {
      this.$emit("changeTab", this.tab_aktif);
    },
  },
};
</script>

<style lang="scss">
.tab {
  &__select {
    .v-text-field {
      .v-input {
        &__control {
          .v-input__slot {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
            .v-select {
              &__selection {
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-transform: initial;
                color: #233f78;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 960px) {
      .v-text-field__details {
        display: flex;
        flex: 1 0 auto;
        max-width: 100%;
        min-height: 14px;
        overflow: hidden;
        margin-top: -10px !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
//section tab personal information dll
@import "../../../assets/scss/color.scss";
.tab {
  &--patient {
    .tab {
      &__content {
        border: 1px solid $main_2;
      }
      &__menu {
        .menu {
          &__item {
            color: $main_2;
            background: white;
            border: 1px solid $main_2;
            &--active {
              background: $main_2;
              color: white;
            }
          }
        }
      }
    }
  }
  &__menu {
    display: flex;
    @media screen and (max-width: 960px) {
      display: none;
    }
    .menu__item {
      overflow: hidden;
      cursor: pointer;
      color: $main_2;
      border: 1px solid $gray_2;
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      padding: 8px 30px;
      background: $gray_7;
      border-radius: 4px 4px 0 0;
      &--active {
        font-weight: bold;
        background: white;
        color: #233f78;
      }
    }
  }
  &__select {
    background: white;
    border-bottom: 1px solid $gray_2;
    display: none;
    @media screen and (max-width: 960px) {
      display: block;
      margin-top: -10px;
    }
  }
  &__content {
    color: $gray_5;
    padding: 12px;
    background: white;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 0 4px 4px 4px;
    display: none;
    &--active {
      display: block;
    }
    @media screen and (max-width: 960px) {
      border-radius: 4px;
    }
  }
}
@media screen and (max-width: 960px) {
  .ic-close {
    margin-left: 160px !important;
  }
}
.ic-close {
  color: #282a30 !important;
  height: 0 !important;
  top: -1px !important;
}
.pr-close {
  position: relative;
}
.pr-close-mobile {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
