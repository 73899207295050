<template>
  <div class="payment-info">
    <v-data-table
      :headers="headers"
      :items="datas"
      class="table-officecare-company"
      :mobile-breakpoint="0"
      hide-default-footer
      :loading="myloadingvariable"
      :items-per-page="-1"
      :custom-sort="customSort"
    >
      <template v-slot:top>
        <v-row class="d-flex flex align-center justify-space-between px-0 py-0">
          <v-col cols="12" md="3" class="d-flex align-center">
            <v-select
              v-model="statusApproval"
              :items="listStatusApprove"
              item-text="title"
              item-value="id"
              dense
              @change="selectedStatusApprove(statusApproval)"
              :label="$t('select_status')"
              outlined
              class="pl-3"
              hide-details
            />
          </v-col>
          <v-col cols="12" md="5" class="d-none d-md-flex"></v-col>
          <v-col cols="12" md="4" class="d-flex justify-end align-center pr-0">
            <v-text-field
              class="searchField fontSet pl-3 pr-3"
              outlined
              dense
              hide-details
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search') + ' ...'"
            ></v-text-field>

            <div>
              <v-select
                class="footerHide"
                v-model="perPage"
                :items="itemShowing"
              ></v-select>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td valign="top" class="pt-2 text-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="approveHrd(item)"
                  v-bind="attrs"
                  v-on="on"
                  style="color: #00a4b3"
                >
                  mdi-file-document-outline
                </v-icon>
              </template>
              <span>{{ $t("approval") }}</span>
            </v-tooltip>
            <!-- </template> -->
          </td>

          <td valign="top" class="pt-2">
            {{ formatDate(item.created_at) }}
          </td>
          <td valign="top" class="pt-2">{{ item.plant }}</td>
          <td valign="top" class="pt-2">{{ item.type }}</td>
          <td valign="top" class="pt-2">
            {{
              item[`item_${$i18n.locale}`] == null
                ? item[`item_en`]
                : item[`item_${$i18n.locale}`]
            }}
          </td>
          <td valign="top" class="pt-2">{{ item.status }}</td>
          <td valign="top" class="pt-2 text-right">
            {{ formatPrice(item.total_invoice) }}
          </td>
        </tr>
      </template>
      <template v-slot:footer>
        <v-row
          class="d-flex flex align-center justify-space-between footer-payment"
        >
          <v-col cols="12" md="8">
            <p class="footer-showing">
              showing {{ itemShowStart }} to {{ itemShowStop }} of
              {{ itemsTotal }} entries
            </p>
          </v-col>
          <v-col cols="12" md="4" class="d-flex justify-end">
            <custom-pagination
              @changePage="(val) => (page = val)"
              :totalPage="itemLength"
              :activePage="page"
            />
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import TabContent from "@/components/v2/tabs/index.vue";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import moment from "moment";
import CustomPagination from "@/components/v3/pagination.vue";

export default {
  components: { CustomPagination },
  data: () => ({
    page: 1,
    options: null,
    itemsTotal: 0,
    itemLength: 1,
    itemShowStart: 0,
    itemShowStop: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    myloadingvariable: true,
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    footerProps: { "items-per-page-options": [25, 50, 70, -1] },
    search: "",
    dialogDelete: false,
    formHasError: false,
    headers: [],
    datas: [],

    editedIndex: -1,
    // registration_group: null,
    kode: null,
    created_at: null,
    status: null,

    errorMessages: "",
    defaultItem: {
      // registration_group: "",
      kode: "",
      created_at: "",
      status: "",
    },
    getAuth: null,
    statusApproval: "",
    listStatusApprove: [],
  }),
  created() {
    this.headers = [
      {
        text: this.$t("page.profile.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
      // {
      //   text: this.$t("page.profile.booking_batch"),
      //   align: "start",
      //   value: "registration_group",
      // },
      {
        text: this.$t("field.booking_date"),
        value: "created_at",
        // sortable: true,
        align: "center",
      },
      {
        text: this.$t("field.branch"),
        value: "branch_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.type_of_test"),
        value: "type",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.item_test"),
        value: "item_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        value: "status",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.amount"),
        value: "total_invoice",
        sortable: false,
        align: "center",
      },
    ];
    if (this.dataProfile) {
      this.getDataEmployee();
    }
    this.getAuth = Auth.getTypeUser();
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page: function () {
      this.getDataEmployee();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getDataEmployee();
      }
    },
    search: function () {
      this.page = 1;
      this.getDataEmployee();
    },
  },
  computed: {
    // get data profile from store
    dataProfile() {
      return this.$store.state.profileData;
    },
  },
  mounted() {
    // filter status
    API.get(`${process.env.VUE_APP_API_TRUST}employee/get-booking-status`, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then((res) => {
      if (res.statusCode == 200) {
        this.listStatusApprove = res.results;
      }
    });
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
          if (index[0]=='created_at') {
            if (!isDesc[0]) {
                return new Date(b[index]) - new Date(a[index]);
            } else {
                return new Date(a[index]) - new Date(b[index]);
            }
          }
          else {
            if(typeof a[index] !== 'undefined'){
              if (!isDesc[0]) {
                 return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              }
              else {
                  return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
      });
      return items;
    },
    formatPrice(price) {
      return Intl.NumberFormat().format(price);
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    approveHrd(item) {
      this.$router.push(
        `/${this.$i18n.locale}/company/examination-approval/approve-list/${item.id}`
      );
    },
   

    async getDataEmployee() {
      // console.log(`${Auth.getToken()}`);
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-list?query=${this.search}&offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
         
          // Check result exist
          if (this.dataProfile) {
            var new_data = [];
            var checkData = [];
            checkData = res.results;

            checkData.map(function (e) {
              var dateCreate =
                typeof e.booking_date !== "undefined" && e.booking_date
                  ? moment(e.booking_date).format("DD-MMM-YYYY").toUpperCase()
                  : "-";
              new_data.push({
                id: typeof e.id !== "undefined" ? e.id : "",

                kode: typeof e.kode !== "undefined" ? e.kode.toUpperCase() : "",
                created_at: dateCreate,
                plant: e.plant !== null ? e.plant.toUpperCase() : "-",
                type: typeof e.type !== "undefined" ? e.type.toUpperCase() : "",
                item_id: e.item_id !== null ? e.item_id.toUpperCase() : "-",
                item_en: e.item_en !== null ? e.item_en.toUpperCase() : "-",
                status:
                  typeof e.status !== "undefined" ? e.status.toUpperCase() : "",
                total_invoice:
                  typeof e.total_invoice !== "undefined" ? e.total_invoice : "",
              });
            });

            this.datas = new_data;

            this.itemsTotal = res.total;
            this.itemLength = Math.ceil(this.itemsTotal / showPage);
            if (this.itemShowStop < 0) {
              this.itemShowStop = res.total;
            }
            if (this.itemShowStop > res.total) {
              this.itemShowStop = res.total;
            }
            this.myloadingvariable = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async selectedStatusApprove(param) {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-list?query=${param}&offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        if (res.results) {
          // Check result exist
          if (this.dataProfile) {
            var new_data = [];
            var checkData = [];

            if (param != null || param != "") {
              checkData = res.results.filter((res) => {
                return res.status == param;
              });
            }

            checkData.map(function (e) {
              var dateCreate =
                typeof e.booking_date !== "undefined" && e.booking_date
                  ? moment(e.booking_date).format("DD-MMM-YYYY").toUpperCase()
                  : "-";
              new_data.push({
                id: typeof e.id !== "undefined" ? e.id : "",
                kode: typeof e.kode !== "undefined" ? e.kode.toUpperCase() : "",
                created_at: dateCreate,
                plant: e.plant !== null ? e.plant.toUpperCase() : "-",
                type: typeof e.type !== "undefined" ? e.type.toUpperCase() : "",
                item_id: e.item_id !== null ? e.item_id.toUpperCase() : "-",
                item_en: e.item_en !== null ? e.item_en.toUpperCase() : "-",
                status:
                  typeof e.status !== "undefined" ? e.status.toUpperCase() : "",
                total_invoice:
                  typeof e.total_invoice !== "undefined" ? e.total_invoice : "",
              });
            });

            this.datas = new_data;

            this.itemsTotal = res.total;
            this.itemLength = Math.ceil(this.itemsTotal / showPage);
            if (this.itemShowStop < 0) {
              this.itemShowStop = res.total;
            }
            if (this.itemShowStop > res.total) {
              this.itemShowStop = res.total;
            }
            this.myloadingvariable = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.table-officecare-company thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-officecare-company thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-officecare-company tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-officecare-company tbody tr:hover {
  background-color: #eee !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}
.searchField {
  max-width: 500px;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button--download {
  background: #79c4c6;
  color: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
}
.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;
  h4 {
    color: $gray_1;
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;
    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--inprogress {
  color: $yellow_1;
  font-weight: 600;
}
.status--complete {
  color: $green_1;
  font-weight: 600;
}
.status--cancel {
  color: $red_1;
  font-weight: 600;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}
td {
  font-size: 12px !important;
  vertical-align: top !important;
}
.fontSet {
  font-size: 14px !important;
}
.button {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 100px !important;
}
a {
  text-decoration: none;
}
.fontSet {
  font-size: 14px !important;
}
.box-page-new {
  margin: 20px;
  border-radius: 4px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-approval .v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-approval .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-approval .v-data-table__wrapper {
  /* overflow-x: auto; */
  overflow-y: auto;
  height: 400px !important;
}
.table-stick-approval thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}
</style>
