<template>
    <div class="home">
      <custom-section class="section pa-0 pa-md-5">
        <NavbarOne
          class="d-md-none d-lg-none"
          :showNavbar="showNavbar"
          :showCart="showCart"
          :showCartDesktop="showCartDesktop"
          :showSearch="showSearch"
          :showLogo="showLogo"
        >
        </NavbarOne>
  
        <TabContent
          :listTab="listMenu"
          :listContent="listContent"
          class="mb-6 corp-m"
          :activeTab="$store.state.activeTab"
          @changeTab="(id) => $store.commit('setActiveTab', id)"
        >
          <template v-slot:tabs_content="content">
            <card v-if="content.id == '1'">
              <template v-slot:body>
                <ExaminationApproval />
              </template>
            </card>
            <card v-if="content.id == '2'">
              <template v-slot:body>
                <ExaminationHistory />
              </template>
            </card>
          </template>
        </TabContent>
      </custom-section>
    </div>
  </template>
  
  <script>
  import TabContent from "@/components/v2/tabs/deposit.vue";
  
  import Card from "@/components/v2/card/index.vue";
  import CustomSection from "@/components/v2/section/index.vue";
  import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
  
  import ExaminationApproval from "@/views/v2/Company/Tabs/ExaminationApproval.vue";
  import ExaminationHistory from "@/views/v2/Company/Tabs/ExaminationHistory.vue";
  
  export default {
    components: {
      TabContent,
      ExaminationApproval,
      ExaminationHistory,
      NavbarOne,
      CustomSection,
      Card,
    },
  
    data: () => ({
      myDepoHistoryListCount: 0,
      showNavbar: true,
      showCart: false,
      showCartDesktop: false,
      showSearch: false,
      showLogo: true,
      listMenu: null,
      listContent: null,
  
      activeTab: "",
      dialog: false,
      show: false,
    }),
    created() {
      this.getTabs();
    },
    async mounted() {
      if (typeof this.$route.query.tab !== "undefined") {
        if (this.$route.query.tab.toUpperCase().includes("SHARE")) {
          this.$store.commit("setActiveTab", 2);
        }
      } else {
        this.$store.commit("setActiveTab", 1);
      }
    },
    methods: {
      async getTabs() {
        (this.listMenu = [
          {
            id: 1,
            title: this.$t("approval"),
          },
          {
            id: 2,
            title: this.$t("tab.history"),
          },
        ]),
          (this.listContent = [
            {
              id: 1,
              title: this.$t("approval"),
              data: [],
            },
            {
              id: 2,
              title: this.$t("tab.history"),
              data: [],
            },
          ]);
  
        // this.activeTab = this.listContent[0].id;
  
        // if (this.$store.state.activeTab == "")
        this.$store.commit("setActiveTab", this.listContent[0].id);
      },
    },
  };
  </script>
  
  <style>
  /* Hide scrollbar for Chrome, Safari and Opera */
  .table-stick-other .v-data-table__wrapper::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .table-stick-other .v-data-table__wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .super-dense > .v-data-table__wrapper > table > tbody > tr > td,
  .super-dense > .v-data-table__wrapper > table > tbody > tr > th,
  .super-dense > .v-data-table__wrapper > table > thead > tr > td,
  .super-dense > .v-data-table__wrapper > table > thead > tr > th,
  .super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 35px !important;
  }
  .table-stick-other .v-data-table__wrapper {
    /* overflow-x: auto; */
    overflow-y: auto;
    height: 500px !important;
  }
  .table-stick-other thead {
    /* position: sticky; */
    inset-block-start: 0;
    top: 0;
    background: #ddd !important;
    font-weight: bold;
    color: #000 !important;
  }
  .table-stick-homedoctor thead {
    /* position: sticky; */
    inset-block-start: 0;
    top: 0;
    background: #ddd !important;
    font-weight: bold;
    color: #000 !important;
  }
  .table-stick-homedoctor thead tr th {
    white-space: nowrap;
  }
  .table-stick-homedoctor tbody tr td {
    white-space: nowrap;
  }
  
  .table-account-balance-company thead {
    background: #ddd !important;
    font-weight: bold;
    color: #000 !important;
  }
  .table-account-balance-company thead tr th {
    height: 38px !important;
    white-space: nowrap;
  }
  .table-account-balance-company tbody tr td {
    font-size: 12px !important;
    height: 35px !important;
    white-space: nowrap;
  }
  .table-account-balance-company tbody tr:hover {
    background-color: transparent !important;
  }
  .corp-m {
    margin-top: 4px !important;
  }
  @media screen and (max-width: 767px) {
    .corp-m {
      margin-top: -10px !important;
    }
  }
  </style>
  <style lang="scss" scoped>
  @import "@/assets/scss/color.scss";
  .home {
    display: flex;
    flex-direction: column;
    padding: 1px 0 40px 0;
    &__section {
      padding: 0 20px;
    }
  }
  .center-empty {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tab-switch {
    color: $main_2;
    margin-right: 8px;
  }
  
  .h4 {
    color: $main_2;
  }
  .list-data {
    border-bottom: 1px solid $gray_2;
    // padding: 4px 8px 0 8px;
    padding: 12px 8px 0 8px;
    height: 100%;
  }
  .pointer {
    cursor: pointer;
  }
  .card-chart {
    position: relative;
  }
  .text-title {
    font-size: 12px;
    text-transform: uppercase;
    color: $main_2;
    font-weight: bold;
  }
  .text-value {
    font-size: 12px;
    text-transform: uppercase;
    color: #000;
    text-align: right;
  }
  
  .v-tabs {
    width: 720px;
  }
  
  .center-chart {
    background: transparent;
    height: 80%;
    width: 80%;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    position: absolute;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 72px;
      margin-bottom: 0;
    }
  }
  .label-doughnut {
    align-self: center;
  }
  .doughnut-title {
    padding: 4px;
  }
  .chart-title {
    padding: 4px 12px;
  }
  
  .v-input__control {
    height: 30px;
  }
  .v-input__slot {
    height: 30px;
  }
  
  .v-select__selections {
    width: 30px;
    height: 30px;
  }
  
  .v-select {
    height: 30px;
    background: #ffb946;
    color: #000;
  }
  
  .sticky-table-footer {
    td {
      font-weight: bold;
    }
  }
  
  .card-chart .v-data-table {
    td {
      font-size: 12px;
    }
  }
  
  .table-patient {
    th {
      .text-gray {
        color: $main_2;
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
    td {
      .text-gray {
        color: $gray_table;
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      .text-black {
        color: $black_table;
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  
  .card-collapse {
    padding: 0px 0px 12px 0px;
  }
  .card-collapse-mobile {
    padding: 0px;
  }
  .menu__icon {
    margin-right: 14px;
    display: flex;
    align-items: center;
    width: 20px;
  }
  .card-booking {
    padding: 10px 15px;
    border-radius: 10px;
    background: #ffffff;
    height: 100%;
    border: 1px solid #777777;
    box-sizing: border-box;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  }
  .booking-title {
    color: #1f3c87;
    font-weight: 700;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .product-name {
    color: #1f3c87;
    font-weight: 600;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 13px;
  }
  .booking-detail {
    color: #1f3c87;
    font-size: 13px;
  }
  // .booking-city {
  //   @media screen and (min-width: 960px) {
  //     padding-top: 0px;
  //   }
  // }
  .text-title {
    font-size: 12px;
    text-transform: uppercase;
    color: $main_2;
    font-weight: bold;
  }
  .text-new-title {
    font-size: 12px;
    text-transform: uppercase;
    color: #000;
  }
  .text-value {
    font-size: 12px;
    text-transform: uppercase;
    color: #000;
    text-align: right;
  }
  
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 12px;
    vertical-align: baseline;
    padding-top: 6px;
    padding-bottom: 6px;
    height: 25px !important;
  }
  
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    height: 35px !important;
  }
  a {
    text-decoration: none;
  }
  </style>
  